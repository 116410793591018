<template>
  <v-container fluid>
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
      min-width="360px"
    >
      <v-card>
        <v-toolbar color="blue" flat>
          <v-toolbar-title class="white--text mx-2"
            >Change password
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="passwordResetSuccess"
          ><p class="body-1 pt-10 pb-5">
            Your password has been updated successfully.
          </p>
          <v-btn color="blue" class="white--text" @click="$router.push('/')"
            >Dismiss</v-btn
          >
        </v-card-text>
        <v-card-text v-if="!passwordResetSuccess">
          <div class="pt-3 body-1">
            In order to <strong>protect your account</strong>, make sure your
            password:
          </div>
          <div class="pb-5">
            <ul>
              <li>Is longer than 8 characters</li>
              <li>Contains upper and lower case characters</li>
              <li>Contains at least 1 digit</li>
              <li>Contains at least 1 symbols</li>
              <li>Is not a common password</li>
            </ul>
          </div>
        </v-card-text>
        <v-card-text v-if="!passwordResetSuccess">
          <v-form ref="loginForm" class="mt-n8" lazy-validation>
            <v-row dense>
              <v-col cols="12">
                <v-alert v-if="error" class="mt-5 pa-2" type="error">
                  {{ error }}
                </v-alert>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="oldPassword"
                  dense
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  label="Current password"
                  hint="At least 8 characters"
                  counter
                  :rules="[validateLength(oldPassword, 50)]"
                  @keydown="error = null"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="newPassword"
                  dense
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  label="New password"
                  hint="At least 8 characters"
                  counter
                  :rules="[validateLength(newPassword, 50)]"
                  @keydown="error = null"
                  @click:append="showPassword = !showPassword"
                  @keyup.enter="changePassword"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="confirmPassword"
                  dense
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  label="Confirm password"
                  hint="At least 8 characters"
                  counter
                  :rules="[validateLength(confirmPassword, 50)]"
                  @keydown="error = null"
                  @click:append="showPassword = !showPassword"
                  @keyup.enter="changePassword"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                {{ passwordStrength }}
              </v-col> </v-row
            ><v-row>
              <v-col cols="4">
                <v-btn block color="cancel" :to="{ name: 'Home' }" link>
                  Cancel
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn
                  block
                  :loading="loadingSubmitButton"
                  color="success"
                  @keyup.enter="changePassword"
                  @click="changePassword"
                >
                  Update password
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import * as inputValidationRules from '@/components/inputValidationRules'
import { NotificationsPrototype } from '@/components/prototypes/notifications'
const Firebase = require('@/firebaseConfig.js')
export default {
  name: 'ChangePassword',
  data() {
    const data = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      error: false,
      showDialog: true,
      showPassword: false,
      passwordResetSuccess: false,
      loadingSubmitButton: false
    }
    return { ...data, ...inputValidationRules }
  },
  computed: {
    ...mapState({
      CurrentUser: (state) => state.moduleUser.CurrentUser
    }),
    passwordStrength() {
      let taiPasswordStrength = require('tai-password-strength')
      let strengthTester = new taiPasswordStrength.PasswordStrength()
      let strength = strengthTester.check(this.newPassword).strengthCode
      if (strength === null) return 'Password strength:'
      return 'Password strength: ' + strength.replace('_', ' ').toLowerCase()
    }
  },
  methods: {
    async changePassword() {
      let _this = this
      this.loadingSubmitButton = true
      let rejectMessage = null
      let taiPasswordStrength = require('tai-password-strength')
      let passwordValidator = require('password-validator')
      let strengthTester = new taiPasswordStrength.PasswordStrength()
      let schema = new passwordValidator()
      schema
        .is()
        .min(8)
        .is()
        .max(50)
        .has()
        .uppercase()
        .has()
        .lowercase()
        .has()
        .digits()
        .has()
        .symbols()
      if (this.newPassword !== this.confirmPassword)
        rejectMessage = 'New password does not match confirmed password'
      if (schema.validate(this.newPassword) === false)
        rejectMessage =
          'New password rejected because it does not meet the password policy critera: ' +
          schema.validate(this.newPassword, { list: true })
      if (strengthTester.check(this.newPassword).commonPassword === true)
        rejectMessage =
          'New password rejected because it does not meet the password policy critera: common password'
      if (rejectMessage) {
        new NotificationsPrototype().create(
          'Change Password',
          'warning',
          rejectMessage
        )
        this.error = rejectMessage
        this.passwordResetSuccess = false
        this.oldPassword = null
        this.newPassword = null
        this.confirmPassword = null
        this.loadingSubmitButton = false
        return
      }
      Firebase.auth
        .signInWithEmailAndPassword(this.CurrentUser.email, this.oldPassword)
        .then(() => {
          Firebase.auth.currentUser
            .updatePassword(this.newPassword)
            .then(function () {
              new NotificationsPrototype().create(
                'Change Password',
                'information',
                'Successfully updated password.'
              )
              _this.passwordResetSuccess = true
              _this.loadingSubmitButton = false
            })
            .catch(function (_error) {
              new NotificationsPrototype().create(
                'Change Password',
                'warning',
                `Error while changing password: ${_error}`
              )
              _this.error = _error
              _this.passwordResetSuccess = false
              _this.loadingSubmitButton = false
            })
        })
        .catch((_error) => {
          new NotificationsPrototype().create(
            'Change Password',
            'warning',
            `Error while changing password: ${_error.message}`
          )
          _this.error = _error.message
          _this.passwordResetSuccess = false
          _this.loadingSubmitButton = false
        })
    }
  }
}
</script>
